import React from 'react'
import tw from 'twin.macro'

export default function HeaderTab({ name, children, isActive }) {
  return (
    <button
      css={[
        tw`relative w-32 h-full flex flex-col items-center justify-center text-white text-opacity-50 focus:outline-none`,
        isActive &&
          tw`text-opacity-100 after:(content[''] bottom[-18px] absolute w-full h-2 bg-white rounded border-b-4 border-grey-2)`,
      ]}
    >
      <div tw="w-5 h-5">{children}</div>
      <div tw="mt-2 text-sm">{name}</div>
    </button>
  )
}
