import { createContext, useContext, useMemo, useState } from 'react'

const AggregateDataContext = createContext()

export function useAggregateData() {
  const context = useContext(AggregateDataContext)
  if (context === undefined) {
    throw new Error(
      'useAggregateData must be used within an AggregateDataProvider'
    )
  }

  return context
}

export function AggregateDataProvider({ children }) {
  const [aggregateData, setAggregateData] = useState(null)

  const value = useMemo(() => [aggregateData, setAggregateData], [
    aggregateData,
  ])

  return (
    <AggregateDataContext.Provider value={value}>
      {children}
    </AggregateDataContext.Provider>
  )
}
