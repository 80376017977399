import { useState, useCallback, useContext, createContext } from 'react'
import getQuarter from 'date-fns/getQuarter'

export const YEARS = 'years',
  QUARTERS = 'quarters',
  MONTHS = 'months'

export function time(date, { interval = YEARS } = {}) {
  date = new Date(date)

  const year = String(date.getFullYear())

  if (interval === YEARS) {
    return year
  }

  if (interval === QUARTERS) {
    const q = getQuarter(date)
    return `Q${q}/${year}`
  }

  // Else, include month level
  const month = String(date.getMonth() + 1).padStart(2, '0')

  if (interval === MONTHS) {
    return `${year}-${month}`
  }

  throw new Error('Invalid interval option in useTime')
}

export function sortQuarters(a, b) {
  const pattern = /(Q\d)\/(\d{4})/
  const [, quarterA, yearA] = a.match(pattern)
  const [, quarterB, yearB] = b.match(pattern)

  if (yearA < yearB) {
    return -1
  }
  if (yearA > yearB) {
    return 1
  } else {
    if (quarterA < quarterB) {
      return -1
    }
    if (quarterA > quarterB) {
      return 1
    }
    return 0
  }
}

const TimeContext = createContext()

export function useTime() {
  const context = useContext(TimeContext)
  if (context === undefined) {
    throw new Error('useTime must be used within a TimeProvider')
  }
  return context
}

export function TimeProvider({ children }) {
  const [interval, setInterval] = useState(YEARS)

  const byTime = useCallback((date) => time(date, { interval }), [interval])

  const sortTime = useCallback(
    (a, b) => {
      if (interval === QUARTERS && a.startsWith('Q')) {
        return sortQuarters(a, b)
      }

      return a.localeCompare(b)
    },
    [interval]
  )

  const value = {
    interval,
    setInterval,
    byTime,
    sortTime,
  }

  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>
}
