import 'twin.macro'

import PoweredByGigmade from '../images/svg/powered-by-gigmade.svg'

export default function Footer() {
  return (
    <footer tw="w-full px-4 h-12 flex items-center bg-grey-2">
      <a href="http://www.gigmade.com" target="_blank" rel="noreferrer">
        <PoweredByGigmade tw="w-24 text-white fill-current stroke-current" />
      </a>
    </footer>
  )
}
