import { useState, useCallback, useContext, createContext } from 'react'
import { sortInOrder } from '../../../util'

const defaultHaulLimits = { short: 1500, long: 4500 }

const [LONG, MID, SHORT] = ['long haul', 'mid haul', 'short haul']

export function haul(leg, { haulLimits = defaultHaulLimits } = {}) {
  // rountrip: undefined if leg, true/false if trip.
  const { km, roundtrip } = leg

  // A round-trip with multiple legs: haul is half-distance of the full trip.
  const haulKm = roundtrip ? km / 2 : km

  if (haulKm < haulLimits.short) {
    return SHORT
  }
  if (haulKm > haulLimits.long) {
    return LONG
  }
  return MID
}

export function sortHaulOrder(a, b) {
  const order = [SHORT, MID, LONG]

  return sortInOrder(a, b, order)
}

const HaulContext = createContext()

export function useHaul() {
  const context = useContext(HaulContext)
  if (context === undefined) {
    throw new Error('useHaul must be used within a HaulProvider')
  }
  return context
}

export function HaulProvider({ children }) {
  const [haulLimits, setHaulLimits] = useState(defaultHaulLimits)

  const byHaul = useCallback(
    (leg) => {
      return haul(leg, { haulLimits })
    },
    [haulLimits]
  )

  const sortHaul = useCallback((a, b) => sortHaulOrder(a, b), [])

  const value = { haulLimits, setHaulLimits, byHaul, sortHaul }

  return <HaulContext.Provider value={value}>{children}</HaulContext.Provider>
}
