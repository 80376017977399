import React, { useContext, useState, useMemo, createContext } from 'react'

const PerFteContext = createContext()

export function usePerFte() {
  const context = useContext(PerFteContext)
  if (context === undefined) {
    throw new Error('usePerFte must be used within a PerFteProvider')
  }
  return context
}

export function PerFteProvider({ children }) {
  const [perFte, setPerFte] = useState(false)

  const values = useMemo(() => ({ perFte, setPerFte }), [perFte])

  return (
    <PerFteContext.Provider value={values}>{children}</PerFteContext.Provider>
  )
}
