import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import 'twin.macro'

import '../static/fonts/fonts.css'

import CSSReset from './CSSReset'
import Header from './Header'
import Footer from './Footer'
import { HaulProvider } from '../connectors/dataSelection/filters/useHaul'
import { TimeProvider } from '../connectors/dataSelection/filters/useTime'
import { LocationProvider } from '../connectors/dataSelection/filters/useLocation'
import { FilterProvider } from '../connectors/filterContext'
import { IndicatorTripModeProvider } from '../connectors/indicatorTripModeContext'
import CombinedProviders from './CombinedProviders'
import { AggregateDataProvider } from '../connectors/aggregateDataContext'
import { DateProvider } from '../connectors/dateContext'
import { ActiveFilterIndicesProvider } from '../connectors/activeFilterIndices'
import { PerFteProvider } from '../connectors/perFteContext'

const providers = [
  HaulProvider,
  TimeProvider,
  LocationProvider,
  FilterProvider,
  IndicatorTripModeProvider,
  DateProvider,
  AggregateDataProvider,
  ActiveFilterIndicesProvider,
  PerFteProvider,
]

export default function Layout({ children, ...rest }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const { title, description } = site.siteMetadata

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <html lang="en" />
      </Helmet>
      <CSSReset />
      <div
        {...rest}
        tw="min-h-screen lg:h-screen flex flex-col lg:overflow-hidden"
      >
        <Header />
        <main tw="w-full flex-1 flex flex-col">
          <CombinedProviders providers={providers}>
            {children}
          </CombinedProviders>
        </main>
      </div>
      <Footer />
    </>
  )
}
