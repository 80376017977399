import React, { useRef, useState } from 'react'
import { ClassNames } from '@emotion/react'
import { Dialog, Transition } from '@headlessui/react'
import tw from 'twin.macro'

import IoInformationCircleSharp from '../images/svg/IoInformationCircleSharp.svg'

/**
 * TODO: Accessibility: Add Dialog.Title and Dialog.Description.
 * See https://headlessui.dev/react/dialog#dialog-title
 * See https://headlessui.dev/react/dialog#dialog-description
 */
export default function InfoModal({ children, containerStyles }) {
  const [isOpen, setIsOpen] = useState(false)
  const focusRef = useRef(null)

  const handleClick = (e) => {
    e.stopPropagation()
    setIsOpen(true)
  }

  return (
    <div css={containerStyles}>
      {/* TODO: Use another icon - ring edges look rough */}
      <button
        tw="w-4 h-4 text-blue-400 bg-white transition rounded-full border-0 ring ring-inset ring-current hover:text-blue-300 focus:outline-none"
        onClick={handleClick}
      >
        <IoInformationCircleSharp tw="w-full h-full pointer-events-none" />
      </button>

      <Transition show={isOpen}>
        <Dialog
          onClose={() => setIsOpen(false)}
          initialFocus={focusRef}
          css={containerStyles}
        >
          <ClassNames>
            {({ css }) => (
              <Transition.Child
                enter={css(
                  tw`transform transition ease-out delay-150 duration-300`
                )}
                enterFrom={css(tw`opacity-0 -translate-y-4`)}
                enterTo={css(tw`opacity-100 translate-y-0`)}
                leave={css(tw`transform transition ease-in`)}
                leaveFrom={css(tw`opacity-100 translate-y-0`)}
                leaveTo={css(tw`opacity-0 -translate-y-5`)}
                tw="fixed inset-0 flex items-center justify-center"
              >
                <Dialog.Overlay tw="absolute w-full h-full bg-black bg-opacity-20" />
                <div
                  css={[
                    tw`z-10 w-auto max-w-prose bg-white shadow-xl rounded-md overflow-y-hidden border border-transparent`,
                    tw`font-light text-sm text-grey-2 ring-1 ring-grey-3 ring-opacity-30`,
                  ]}
                  ref={focusRef}
                >
                  <div tw="p-6 h-full maxHeight[80vh] overflow-y-auto">
                    {children}
                    <button
                      tw="mt-6 px-4 py-2 transition bg-blue text-sm font-medium text-white rounded-md hover:bg-blue-400 focus:outline-none focus-visible:(bg-blue-400 ring-2 ring-offset-2 ring-blue)"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            )}
          </ClassNames>
        </Dialog>
      </Transition>
    </div>
  )
}

export function InfoHeading({ text }) {
  return <h3 tw="text-lg font-medium text-grey-1">{text}</h3>
}

export function InfoParagraph({ children }) {
  return <p tw="mt-3 text-base leading-tight text-grey-2">{children}</p>
}

export function InfoList({ children, ...rest }) {
  return (
    <ul tw="mt-1 text-base flex flex-col space-y-1" {...rest}>
      {children}
    </ul>
  )
}

export function InfoListItem({ children }) {
  return (
    <li tw="relative pl-4 before:(content inline-block absolute top-2 left-0 w-1.5 h-1.5 rounded-md bg-blue)">
      {children}
    </li>
  )
}
