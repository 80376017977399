import { useState, useCallback, useContext, createContext } from 'react'
import useData from '../../../../src/data/useData'

import { getRemoteIata, getLocationLevel } from '../../../location'

export const CITY = 'city'

/*
 * levels: iata, city, country
 */
export function location(
  airports,
  fromIata,
  toIata,
  { locLevel = 'city' } = {}
) {
  const remoteIata = getRemoteIata(airports, fromIata, toIata) ?? 'Unknown'

  if (locLevel === 'iata') {
    return remoteIata
  }

  return getLocationLevel(airports, remoteIata, locLevel) ?? 'Unknown'
}

const LocationContext = createContext()

export function useLocation() {
  const context = useContext(LocationContext)
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider')
  }
  return context
}

export function LocationProvider({ children }) {
  const { airports } = useData()
  const [locLevel, setLocLevel] = useState(CITY)

  const byLocation = useCallback(
    (...args) => location(airports, ...args, { locLevel }),
    [locLevel, airports]
  )

  const value = {
    locLevel,
    setLocLevel,
    byLocation,
  }

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}
