import { max } from 'd3-array'
import roundTo from 'round-to'

// TODO: Break into separate files by domain
export function thousandSeparator(params, separator = ',') {
  let [integer, decimals] = params.toString().split('.')

  const re = /(-?\d+)(\d{3})/
  while (re.test(integer)) {
    integer = integer.replace(re, `$1${separator}$2`)
  }

  return `${integer}${decimals ? `.${decimals}` : ''}`
}

export function capitalize(text) {
  return text && `${text.toString().charAt(0).toUpperCase()}${text.slice(1)}`
}

/**
 * Round a number and return a string sepsarated in 1000s.
 * @param {number} value - The number to round to string
 * @param {number} [precision=0] - The precision to round the value to.
 * @returns {string}
 */
export function getRoundedString(value, precision = 0) {
  return thousandSeparator(roundTo(value, precision), ' ')
}

export function getSubtitle(value, unit) {
  return `${value}${unit ? ` ${unit}` : ''}`
}

export function sortInOrder(a, b, order) {
  return order.indexOf(a) - order.indexOf(b)
}

/**
 * Get max value from all key-value pairs where value determines the y-scale domain.
 * @param {Object} data The data with key-values for x and y scales.
 * @param {string} xProp The key to skip because its value determines the x-scale domain.
 * @returns {number} The max value of y from summing values of all keys in each data "row".
 */
export function getMaxYValue(data, xProp) {
  return max(
    data.map((d) =>
      Object.entries(d)
        .filter(([key]) => key !== xProp)
        .reduce((acc, [, value]) => (acc += value), 0)
    )
  )
}

export function getByYear(data) {
  const byYear = data.reduce((acc, { x, y }) => {
    const total = acc?.[x]?.y ? acc[x].y + y : y

    return {
      ...acc,
      [x]: {
        x,
        y: total,
      },
    }
  }, {})

  return Object.values(byYear)
}
