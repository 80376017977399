import { createContext, useState, useContext, useMemo } from 'react'

import { legsToTrips } from '../../location'

export const getFlightTrips = (airports, flightLegs) =>
  legsToTrips(airports, flightLegs)

const IndicatorTripModeContext = createContext()

export function useIndicatorTripMode() {
  const context = useContext(IndicatorTripModeContext)
  if (context === undefined) {
    throw new Error(
      'useIndicatorTripMode must be used within an IndicatorTripModeProvider'
    )
  }

  return context
}

export function IndicatorTripModeProvider({ children }) {
  const [indicatorIndex, setIndicatorIndex] = useState(0)
  const [tripMode, setTripMode] = useState(true)

  const value = useMemo(
    () => ({
      indicatorIndex,
      setIndicatorIndex,
      tripMode,
      setTripMode,
    }),
    [indicatorIndex, tripMode]
  )

  return (
    <IndicatorTripModeContext.Provider value={value}>
      {children}
    </IndicatorTripModeContext.Provider>
  )
}
