import {
  addQuarters,
  startOfQuarter,
  lastDayOfQuarter,
  addMonths,
  startOfMonth,
  endOfMonth,
  addYears,
  startOfYear,
  lastDayOfYear,
} from 'date-fns'

const START_YEAR = '2017'

const DATE_PICKER_OPTIONS = {
  MONTHS: 'months',
  QUARTERS: 'quarters',
  LAST_YEAR: 'last-year',
  YEARS: 'years',
  ALL_DATA: 'all-data',
}

const getFormattedText = (text) => text.toLowerCase().replaceAll(' ', '-')

const getFormattedDate = (dateString, includeYear = true) => {
  const date = new Date(dateString).toDateString()
  const [, month, day, year] = date.match(/\w{3} (\w{3}) (\d\d) (\d{4})/)

  const formattedDay = day.startsWith('0') ? day[1] : day
  const formattedDate = `${month} ${formattedDay}`

  if (includeYear) {
    return `${formattedDate}, ${year}`
  }

  return formattedDate
}

const getFirstAndLastDaysOfMonth = (start, addToMonth = 0) => {
  const date = addMonths(start, addToMonth)

  return {
    firstDay: startOfMonth(date),
    lastDay: endOfMonth(date),
  }
}

const getFirstAndLastDaysOfQuarter = (start, addToQuarter = 0) => {
  const date = addQuarters(start, addToQuarter)

  return {
    firstDay: startOfQuarter(date),
    lastDay: lastDayOfQuarter(date),
  }
}

const getFirstAndLastDaysOfYear = (start, addToYear = 0) => {
  const date = addYears(start, addToYear)

  return {
    firstDay: startOfYear(date),
    lastDay: lastDayOfYear(date),
  }
}

/**
 * Return year as string given a date
 * @param {Date} date Date object to retrieve year from
 * @param {Boolean} shortened Whether to shorten the returned year string
 * @returns {string} Year in string
 */
const getYear = (date, shortened = false) => {
  const year = date.getFullYear().toString()
  return shortened ? `'${year.slice(2)}` : year
}

const getStartYear = () => new Date(START_YEAR)

const getLastYear = () => new Date(new Date().getFullYear() - 1, 0)

export {
  getFormattedText,
  getFormattedDate,
  getFirstAndLastDaysOfMonth,
  getFirstAndLastDaysOfQuarter,
  getFirstAndLastDaysOfYear,
  getYear,
  getStartYear,
  getLastYear,
  DATE_PICKER_OPTIONS,
}
