import React, { createContext, useState, useContext, useMemo } from 'react'
import useData from '../../../src/data/useData'
import {
  getFirstAndLastDaysOfYear,
  getLastYear,
  getStartYear,
} from '../../components/DateRange/utils'

import { getDateRange } from '../../location'
import {
  useIndicatorTripMode,
  getFlightTrips,
} from '../indicatorTripModeContext'

const DateContext = createContext()

export function useDate() {
  const context = useContext(DateContext)
  if (context === undefined) {
    throw new Error('useDate must be used within a DateProvider')
  }

  return context
}

const startYear = getStartYear()
const { lastDay: lastYear } = getFirstAndLastDaysOfYear(getLastYear())

export function DateProvider({ children }) {
  const { flightLegs, airports } = useData()
  const legDateRange = getDateRange(flightLegs)
  const tripDateRange = getDateRange(getFlightTrips(airports, flightLegs))
  const { tripMode } = useIndicatorTripMode()
  const [date, setDate] = useState({
    min: startYear,
    max: lastYear,
  })

  const value = useMemo(
    () => ({
      dateRange: tripMode ? tripDateRange : legDateRange,
      date,
      setDate,
    }),
    [tripMode, date, legDateRange, tripDateRange]
  )

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>
}
