import React, { useContext, useReducer, createContext } from 'react'

const FilterContext = createContext()

export function useFilter() {
  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider')
  }
  return context
}

export function FilterProvider({ children }) {
  const [filteredList, dispatch] = useReducer(filteredListReducer, [])

  const values = {
    filteredList,
    dispatch,
  }

  return (
    <FilterContext.Provider value={values}>{children}</FilterContext.Provider>
  )
}

const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
}

function filteredListReducer(state, action) {
  switch (action.type) {
    case ACTIONS.ADD: {
      return [...state, { key: action.key, name: action.name }]
    }
    case ACTIONS.REMOVE: {
      return state.filter(
        (filteredItem) =>
          !(
            filteredItem.key === action.key && filteredItem.name === action.name
          )
      )
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
