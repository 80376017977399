import { useMemo, createContext, useContext, useState } from 'react'

function useList(initialList) {
  const [list, setList] = useState(initialList)

  const actions = useMemo(() => {
    const a = {
      set: setList,
      push: (...items) => {
        setList((prevList) => [...prevList, ...items])
      },
      removeAt: (index) => {
        setList((prevList) => [
          ...prevList.slice(0, index),
          ...prevList.slice(index + 1),
        ])
      },
    }
    return a
  }, [])

  return [list, actions]
}

const ActiveFilterIndicesContext = createContext()

export function useActiveFilterIndices() {
  const context = useContext(ActiveFilterIndicesContext)
  if (context === undefined) {
    throw new Error(
      'useActiveFilterIndices must be used within a ActiveFilterIndicesProvider'
    )
  }

  return context
}

export function ActiveFilterIndicesProvider({ children }) {
  const [activeFilterIndices, listActions] = useList([])

  const value = useMemo(() => ({ activeFilterIndices, listActions }), [
    activeFilterIndices,
    listActions,
  ])

  return (
    <ActiveFilterIndicesContext.Provider value={value}>
      {children}
    </ActiveFilterIndicesContext.Provider>
  )
}
