import localforage from 'localforage'
import { unparse } from 'papaparse'

export const saveFile = (file) =>
  localforage.setItem('custom-data', JSON.stringify(file))

let cachedFile = null

export const loadFile = async () => {
  if (cachedFile) {
    return cachedFile
  }

  const file = await localforage.getItem('custom-data')
  if (file) {
    cachedFile = JSON.parse(file)
    return cachedFile
  }

  return null
}

export const removeCachedFile = () => (cachedFile = null)

export function dataToCSV(customData, dataKey) {
  function flatten(fields, data) {
    function flatValues(data) {
      return fields.map((f) => data[f])
    }

    if (!Array.isArray(fields[1])) {
      // Some lowest-level sets are not inside arrays (org_fte)
      const newData = Array.isArray(data)
        ? data.map(flatValues)
        : [flatValues(data)]
      return { fields, data: newData }
    }

    const [key, innerKeys] = fields
    const values = Object.keys(data)

    return {
      // Same fields for all values.
      fields: [key, ...flatten(innerKeys, data[values[0]]).fields],
      data: values
        .map((v) => flatten(innerKeys, data[v]).data.map((d) => [v, ...d]))
        .flat(),
    }
  }

  const { data, fields } = flatten(
    customData.data_specs[dataKey],
    customData[dataKey]
  )

  return unparse({ data, fields }, { header: true })
}
