// @ts-check
import { findNearest, getDistance, orderByDistance } from 'geolib'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import differenceInDays from 'date-fns/differenceInDays'

const homeIata = 'ZRH'

/** @typedef { import('./data/types').Airport} Airport */
/** @typedef { import('./data/types').Leg} Leg */
/** @typedef { import('./data/types').Trip} Trip */

/**
 * Get coordinates of an airport from its IATA
 * @param {Airport[]} airports
 * @param {string} iata
 * @returns {{latitude: number, longitude: number}|undefined}
 */
export const getCoord = (airports, iata) => {
  try {
    return {
      latitude: airports[iata].lat,
      longitude: airports[iata].lon,
    }
  } catch (err) {
    console.error(`No such IATACode ${iata} found: ${err}`)
  }
}

const findMidpoint = (path) => {
  const [firstHalf, secondHalf] = [
    path.slice(0, path.length / 2),
    path.slice(path.length / 2),
  ]

  // Equal inbound and outbound legs so return midpoint
  if (firstHalf[firstHalf.length - 1] === secondHalf[0]) {
    return firstHalf[firstHalf.length - 1]
  }

  return null
}

const findFurthest = (airports, origin, path) => {
  const ports = [...new Set(path.filter((iata) => iata !== origin))]
  const originCoord = getCoord(airports, origin)
  const distancesFromOrigin = orderByDistance(
    originCoord,
    ports.map((port) => getCoord(airports, port))
  )

  const furthest = ports.find((port) => {
    const { latitude, longitude } = getCoord(airports, port)
    const furthestCoord = distancesFromOrigin[distancesFromOrigin.length - 1]
    return (
      latitude === furthestCoord.latitude &&
      longitude === furthestCoord.longitude
    )
  })

  return furthest
}

const getRoundtrip = (airports, legs) => {
  if (legs.length === 1) {
    return false
  }

  const end = legs[legs.length - 1].to

  if (legs[0].from === end) {
    return true
  }

  // if originToEnd <= 0.2 * originToDestination, roundtrip = true
  const originCoord = getCoord(airports, getOrigin(legs))
  const destinationCoord = getCoord(airports, getDestination(airports, legs))
  const endCoord = getCoord(airports, end)

  const originToDestination = getDistance(originCoord, destinationCoord)
  const originToEnd = getDistance(originCoord, endCoord)

  return originToEnd <= 0.2 * originToDestination
}

const getDirect = (airports, legs) => {
  if (legs.length === 1) {
    return true
  }
  if (legs.length === 2 && getRoundtrip(airports, legs)) {
    return true
  }
  return false
}

const getOrigin = (legs) => legs[0].from

const getDestination = (airports, legs) => {
  if (legs.length === 1) {
    return legs[0].to
  }

  // Find midpoint if any (only applicable if legs <= 8)
  const path = legs.reduce((trip, leg) => {
    return [...trip, leg.from, leg.to]
  }, [])
  if (path.length % 2 === 0 && legs.length <= 8) {
    const midpoint = findMidpoint(path)
    if (midpoint) {
      return midpoint
    }
  }

  // Else return furthest coordinates from origin
  return findFurthest(airports, getOrigin(legs), path)
}

const getLocalOrigin = (airports, legs) => {
  // Check if origin is closer to ZRH than to the destination
  const originCoord = getCoord(airports, getOrigin(legs))
  const destinationCoord = getCoord(airports, getDestination(airports, legs))
  const localCoord = getCoord(airports, homeIata)

  return (
    findNearest(localCoord, [originCoord, destinationCoord]) === originCoord
  )
}

const getLegs = (legs) => legs.length

function getMaxClass(legs) {
  const sortedClasses = sortBy(legs, (leg) => {
    return ['F', 'B', 'Y', '', undefined].indexOf(leg.class)
  })

  return sortedClasses[0].class
}

export function getDateRange(legs) {
  const sorted = sortBy(legs, 'leg_date')
  return {
    min: new Date(sorted[0].leg_date),
    max: new Date(sorted[sorted.length - 1].leg_date),
  }
}

function sum(legs, prop) {
  return legs.reduce((acc, leg) => acc + leg[prop], 0)
}

function summedCO2Props(legs) {
  const co2Props = Object.keys(legs[0]).filter((prop) => prop.startsWith('CO2'))

  return Object.assign(
    {},
    ...co2Props.map((prop) => {
      return { [prop]: sum(legs, prop) }
    })
  )
}

function demographicsProps(legs) {
  const props = ['yob', 'gender', 'manager', 'role', 'accountable org code']

  return props.reduce((acc, curr) => ({ ...acc, [curr]: legs[0][curr] }), {})
}

function firstDefinedReason(legs) {
  const withReason = legs.find((leg) => Boolean(leg.flight_reason))

  return withReason?.flight_reason
}

function maxDateDifferenceIfKnown(legs) {
  if (legs.some((leg) => leg.leg_date_unknown)) {
    return
  }

  const { min, max } = getDateRange(legs)
  const duration = differenceInDays(new Date(max), new Date(min))

  return duration
}

/**
 *
 * @param {Airport[]} airports
 * @param {Leg[]} legs
 * @returns {Trip}
 */
export function tripFromLegs(airports, legs) {
  const trip = {
    roundtrip: getRoundtrip(airports, legs),
    direct: getDirect(airports, legs),
    from: getOrigin(legs),
    to: getDestination(airports, legs),
    localOrigin: getLocalOrigin(airports, legs),
    legs: getLegs(legs),
    class: getMaxClass(legs),
    leg_date: getDateRange(legs).min,
    trip_id: legs[0].trip_id,
    km: sum(legs, 'km'),
    ...summedCO2Props(legs),
    flight_reason: firstDefinedReason(legs),
    duration: maxDateDifferenceIfKnown(legs),
    ...demographicsProps(legs),
  }
  return trip
}

export const getLocationLevel = (airports, iata, level) => {
  return airports[iata]?.[level]
}

export const getRemoteIata = (airports, originIata, destinationIata) => {
  try {
    const originCoord = getCoord(airports, originIata)
    const destinationCoord = getCoord(airports, destinationIata)
    const localCoord = getCoord(airports, homeIata)

    return orderByDistance(localCoord, [originCoord, destinationCoord])[1] ===
      originCoord
      ? originIata
      : destinationIata
  } catch (err) {
    return
  }
}

/**
 * @param {Airport[]} airports
 * @param {Leg[]} legs
 * @returns
 */
export function legsToTrips(airports, legs) {
  const grouped = groupBy(legs, (leg) => leg.trip_id)
  const groupedLegs = Object.values(grouped)

  return groupedLegs.map((v) => tripFromLegs(airports, v))
}
