// @ts-check
import { useEffect, useState, useMemo } from 'react'
import { loadFile } from '.'
import dataAnon from './dataset-anon.json'

/** @typedef { import('./types').OrgFte} OrgFte */
/** @typedef { import('./types').YearlyFte} YearlyFte */
/** @typedef { import('./types').OrgFteByYear} OrgFteByYear */

export default function useData() {
  const [data, setData] = useState(dataAnon)

  useEffect(() => {
    const mount = async () => {
      const file = await loadFile()

      if (file?.content) {
        setData(JSON.parse(file.content))
      }
    }
    mount()
  }, [])

  const value = useMemo(
    () => ({
      ...data,
      airports: data.airports,
      flightLegs: data.flight_legs,
      cars: data.cars,
      food: data.food,
      paper: data.paper,
      water: data.water,
      energy: data.energy,
      energyEfficiencyAchieved: data.energy_efficiency_achieved,
      orgFte: data.org_fte,
      // Site energy reference area (ERA)
      buildings: data.buildings,
      // Org code lookups
      orgCodes: data.lookups.org_codes,
      orgColors: data.lookups.org_colors,
    }),
    [data]
  )

  return value
}

const siteKeys = ['campus', 'code', 'name', 'location', 'ownership']

export function useHelperData() {
  const { orgFte, buildings } = useData()

  const { yearlyFte, orgFteByYear } = useMemo(() => {
    return {
      yearlyFte: getYearlyFteForAll(orgFte),
      orgFteByYear: getOrgFteByYear(orgFte),
    }
  }, [orgFte])

  const { eraBySiteYear, eraByYear } = useMemo(() => {
    return {
      eraBySiteYear: (site, year) => {
        return buildings[year]
          .filter((item) => {
            return siteKeys.some((key) => {
              return item[key] === site
            })
          })
          .reduce((pv, item) => {
            return pv + item['era (m2)']
          }, 0)
      },
      eraByYear: (year) => {
        return buildings[year].reduce((pv, item) => {
          return pv + item['era (m2)']
        }, 0)
      },
    }
  }, [buildings])

  return { yearlyFte, orgFteByYear, eraBySiteYear, eraByYear }
}

export function useHasCustomData() {
  const [hasData, setHasData] = useState(false)

  useEffect(() => {
    const mount = async () => {
      const file = await loadFile()

      if (file?.content) {
        setHasData(true)
      } else {
        setHasData(false)
      }
    }
    mount()
  })
  return hasData
}

/**
 * Get total FTE by all departments for each year
 * @param {OrgFte} orgFte
 * @returns {YearlyFte}
 */
export function getYearlyFteForAll(orgFte) {
  const result = Object.fromEntries(
    Object.entries(orgFte).map(([key, value]) => {
      return [key, value['_all_'].fte]
    })
  )
  return result
}

/**
 * Get FTE values of all top-level department from every year
 * @param {OrgFte} orgFte
 * @returns {OrgFteByYear}
 */
export function getOrgFteByYear(orgFte) {
  const result = Object.fromEntries(
    Object.entries(orgFte).map(([key, value]) => {
      const fteByOrg = Object.entries(value)
        .filter(([key]) => key.length === 1) // Filter by highest level of organizations
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value.fte }), {})

      return [key, fteByOrg]
    })
  )
  return result
}
